





































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component
export default class MemberResetPassword extends Vue {
  public $store: any;
  public $q: any;
  public $route: any;
  public loading: boolean = false;
  public email: string = '';
  public error: any = null;
  public done: boolean = false;

  private handleSubmit() {
    const email: string = this.email;
    this.$store.dispatch('resetPasswordAsk', {
      payload: {
        email,
      },
    }).then((response: any) => {
      this.loading = false;
      this.done = true;
    }).catch((error: any) => {
      this.loading = false;
      this.error = error.message;
    });
  }

}
